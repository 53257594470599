<template>
<div>
  <div style="width:100%;height:570px;overflow-y:auto;margin-top:10px">
      <div style="width:100%;height:570px;background:#fff;margin-bottom:1%;">
          <div style="width:100%;height:60px;" class="keList">
            <div v-for="(item,index) in keList" :key="index" @click="clikeyindex(index)" :class="keyindex == index ? 'keyindexclass' : ''">{{item.name}}</div>
          </div>
          <!-- 历史作业  -->
          <div v-if="keyindex==0" style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
            <el-select v-model="courseId" class="m-2" clearable placeholder="课程名称" size="large" style="margin-left: 10px;">
                <el-option
                  v-for="item in courseOptions"
                  :key="item.id"
                  :label="item.subjectName"
                  :value="item.id"
                />
            </el-select>
          
           <div style="margin-left: 10px;">
            <el-input v-model="name0" placeholder="作业名称"></el-input>
           </div>
            
            <div>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="JobQuery">查询</el-button>
            </div>
        </div>
        <!-- 实训成绩  -->
        <div v-if="keyindex==1" style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
          <el-select v-model="courseId1" class="m-2" clearable placeholder="实训类型" size="large" style="margin-left: 10px;">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.subjectName"
                :value="item.id"
              />
          </el-select>
        
         <div style="margin-left: 10px;">
          <el-input v-model="name1" placeholder="实训名称"></el-input>
         </div>
          
          <div>
              <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="JobQuery1">查询</el-button>
          </div>
      </div>
       
          <!-- 历史考试  -->
          <div v-if="keyindex==2" style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
              <el-select v-model="subjectId" class="m-2" clearable placeholder="课程名称" size="large" style="margin-left: 10px;">
                  <el-option
                    v-for="item in courseOptions"
                    :key="item.id"
                    :label="item.subjectName"
                    :value="item.id"
                  />
              </el-select>
            
             <div style="margin-left: 10px;">
              <el-input v-model="examinationName" placeholder="考试名称"></el-input>
             </div>
              
              <div>
                  <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="Querying">查询</el-button>
              </div>
          </div>
           <!-- 历史习题  -->
        <div v-if="keyindex==3" style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
          <el-select v-model="courseId2" class="m-2" clearable placeholder="课程名称" size="large" style="margin-left: 10px;">
              <el-option
                v-for="item in courseOptions"
                :key="item.id"
                :label="item.subjectName"
                :value="item.id"
              />
          </el-select>
        
         <div style="margin-left: 10px;">
          <el-input v-model="name3" placeholder="习题名称"></el-input>
         </div>
          
          <div>
              <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="JobQuery3">查询</el-button>
          </div>
      </div>
          
          <!-- 历史作业 -->
          <div v-if="keyindex==0">
            <el-table :data="tableData0" stripe style="width: 100%;height:460px" max-height="500px">
              <el-table-column type="index" label="序号" align="center"/>
              <el-table-column prop="courseName" label="课程名称" align="center"/>
              <el-table-column prop="exercisesnName" label="作业名称" width="180" align="center"/>
              <el-table-column prop="insertTime" label="提交时间"  width="280" align="center"/>
              <el-table-column prop="allCount" label="试题数量" align="center"/>
              <el-table-column prop="rightCount" label="正确数量" align="center"/>
              <el-table-column prop="errorCount" label="错误数量" align="center"/>
              <el-table-column label="操作" align="center">
                <template #default="{row}">
                  <el-button @click="handleClick(row,'历史作业预览')" type="text" size="small">试卷预览</el-button>
                </template>
              </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination small  v-model:current-page="currentPage0" layout="prev, pager, next" :total="total0" @current-change="handleCurrentChange0" />
          </div> 
          </div>
          <!-- 实训成绩 -->
          <div v-if="keyindex==1">
            <el-table :data="tableData1" stripe style="width: 100%;height:460px" max-height="500px">
              <el-table-column type="index" label="序号" align="center"/>
              <el-table-column prop="trainingType" label="实训类型" align="center">
                <template #default="scope">
                  <span>{{ scope.row.trainingType ==1?'课程实训':'系统实训' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="exercisesnName" label="实训名称" width="180" align="center"/>
              <el-table-column prop="knowledgeName" label="节名称" width="180" align="center"/>
              <el-table-column prop="insertTime" label="提交时间"  width="280" align="center"/>
              <el-table-column prop="allCount" label="试题数量" align="center"/>
              <el-table-column prop="rightCount" label="正确数量" align="center"/>
              <el-table-column prop="errorCount" label="错误数量" align="center"/>
              <el-table-column label="操作" align="center">
                <template #default="{row}">
                  <el-button @click="handleClick(row,'实训成绩预览')" type="text" size="small">试卷预览</el-button>
                </template>
              </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination small  v-model:current-page="currentPage1" layout="prev, pager, next" :total="total1" @current-change="handleCurrentChange1" />
          </div> 
          </div>
          <!-- 历史考试 -->
          <div v-if="keyindex==2">
            <el-table :data="tableData2" stripe style="width: 100%;height:460px" max-height="500px">
              <el-table-column type="index" label="序号" align="center"/>
              <el-table-column prop="subjectName" label="课程名称" align="center"/>
              <el-table-column prop="examinationName" label="考试名称" width="180" align="center"/>
              <el-table-column prop="insertTime" label="提交时间"  width="280" align="center"/>
              <el-table-column prop="allCount" label="试题数量" align="center"/>
              <el-table-column prop="rightCount" label="正确数量" align="center"/>
              <el-table-column prop="errorCount" label="错误数量" align="center"/>
              <el-table-column prop="score" label="考试分数" align="center"/>
              <el-table-column label="操作" align="center">
                <template #default="{row}">
                  <el-button @click="handleClick(row,'历史考试预览')" type="text" size="small">试卷预览</el-button>
                </template>
              </el-table-column>
            </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination small  v-model:current-page="currentPage2" layout="prev, pager, next" :total="total2" @current-change="handleCurrentChange2" />
          </div> 
          </div>
          <!-- 历史习题 -->
          <div v-if="keyindex==3">
            <el-table :data="tableData3" stripe style="width: 100%;height:460px" max-height="500px">
              <el-table-column type="index" label="序号" align="center"/>
              <el-table-column prop="courseName" label="课程名称" align="center"/>
              <el-table-column prop="exercisesnName" label="习题名称" width="180" align="center"/>
              <el-table-column prop="insertTime" label="提交时间"  width="280" align="center"/>
              <el-table-column prop="allCount" label="试题数量" align="center"/>
              <el-table-column prop="rightCount" label="正确数量" align="center"/>
              <el-table-column prop="errorCount" label="错误数量" align="center"/>
              <el-table-column label="操作" align="center">
                <template #default="{row}">
                  <el-button @click="handleClick(row,'历史习题预览')" type="text" size="small">试卷预览</el-button>
                </template>
              </el-table-column>
            </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination small  v-model:current-page="currentPage3" layout="prev, pager, next" :total="total3" @current-change="handleCurrentChange3" />
          </div> 
          </div>
      </div>

    <el-dialog
      v-model="PreviewDialog"
      :title="PreviewDialogtitle"
      width="60%"
      align-center
    >
      <div
          style=" width: 100%;height: 30px;background: #fff;border-top: 0.5px solid #edeff2;line-height: 30px;display: flex;align-items: center;">
          <!-- <div style="font-size:14px;margin:0 0 0 20px;">
              <span style="color:#666;">学生姓名：</span>
              <span style="color:#333;">{{ rowname }}</span>
          </div> -->
      </div>
      <div style="width: 100%;height: 700px;overflow-y: auto;">
              <!--  -->
              <p style="font-size: 16px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;" v-if="Fillintheblanks.length != 0">一、填空题</p>
              <div v-if="Fillintheblanks.length != 0">
                  <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                      v-for="(item, index) in Fillintheblanks" :key="index">
                      <p style="font-size: 14px;">
                          <span
                              style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                          <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                          <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                          <span >
                              <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                  <span>学生答案：</span>
                                  <span>{{item.questionAnswerContent}}</span>
                              </span>
                              <span style="margin:0px 20px;color:#76C8F6;">
                                  <span>正确答案：</span>
                                  <span>{{answer(item.answers)}}</span>
                              </span>
                          </span>
                          
                      </p>
                      <el-input placeholder="" size="mini" style="margin-top: 10px;"
                          v-for="(item2, index2) in item.answers" :key="index2" v-model="item2.dev">
                          <template #prepend>{{ index2+=1 }}</template>
                      </el-input>
                  </div>
              </div>
              <!--  -->
              <p style="font-size: 16px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;padding-top: 10px;" v-if="SingleSelection.length != 0">二、单选题</p>
              <div v-if="SingleSelection.length != 0">
                  <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                      v-for="(item, index) in SingleSelection" :key="index">
                      <p style="font-size: 14px;">
                          <span
                              style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                          <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                           <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                           <span >
                              <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                  <span>学生答案：</span>
                                  <span>{{yesanswer2(item.questionAnswer)}}</span>
                              </span>
                              <span style="margin:0px 20px; color:#76C8F6;">
                                  <span>正确答案：</span>
                                  <span>{{answer2(item.answers)}}</span>
                              </span>
                          </span>
                      </p>
                      <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                          <el-radio v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                              {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                          </el-radio>
                      </el-radio-group>
                  </div>
              </div>
              <!--  -->
              <p style="font-size: 16px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;padding-top: 10px;" v-if="Multiple.length != 0">三、多选题</p>
              <div v-if="Multiple.length != 0">
                  <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Multiple"
                      :key="index">
                      <p style="font-size: 14px;">
                          <span
                              style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                          <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                           <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                           <span >
                              <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                  <span>学生答案：</span>
                                  <span>{{yesanswer2(item.questionAnswer)}}</span>
                              </span>
                              <span style="margin:0px 20px; color:#76C8F6;">
                                  <span>正确答案：</span>
                                  <span>{{answer2(item.answers)}}</span>
                              </span>
                          </span>
                      </p>
                      <el-checkbox-group v-model="item.dev">
                          <el-checkbox v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                              {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                          </el-checkbox>
                      </el-checkbox-group>
                  </div>
              </div>
              <!--  -->
              <p style="font-size: 16px;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  color: #333333;padding-top: 10px;" v-if="Judgment.length != 0">四、判断题</p>
              <div v-if="Judgment.length != 0">
                  <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Judgment"
                      :key="index">
                      <p style="font-size: 14px;">
                          <span
                              style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                          <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                           <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                           <span>
                              <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                  <span>学生答案：</span>
                                  <span>{{yesanswer2(item.questionAnswer)}}</span>
                              </span>
                              <span style="margin:0px 20px; color:#76C8F6;">
                                  <span>正确答案：</span>
                                  <span>{{answer2(item.answers)}}</span>
                              </span>
                          </span>
                      </p>
                      <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                          <el-radio v-for="(item2, index2) in item.answers" :key="index2"
                              :label="item2.isOrder">
                              {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                          </el-radio>
                      </el-radio-group>
                  </div>
              </div>
          </div>
    </el-dialog>

    </div>
</div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {f_get_studentJobHistory,f_get_studentTrainingHistory,f_get_studentExaminationHistory} from '@/js/teachingSquare'
import {f_get_ShowJobHistory} from '@/js/homeListApi.js'
import {get_select_subjectList} from '@/js/courseManagementApi'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
              tableData0:[
           
              ],
              tableData1:[
           
              ],
              tableData2:[
           
              ],
              tableData3:[
           
              ],
             
              currentPage0:1,
              currentPage1:1,
              currentPage2:1,
              currentPage3:1,
              total0:0,
              total1:0,
              total2:0,
              total3:0,
              keList:[
                  {
                    name:"历史作业"
                  },
                  {
                    name:"实训成绩"
                  },
                  {
                    name:"历史考试"
                  },{
                    name:'历史习题'
                  }
                  ],
              keyindex:0,
              keList2:[
                  {
                    name:"当前考试"
                  },
                  
                  ],
              keyindex2:0,
              courseOptions:[],
              subjectId:'',
              examinationName:'',
              courseId:'',
              name0:'',
              courseId2:'',
              name3:'',
              courseId1:0,
              name1:'',
              typeList:[
                {
                  id:0,
                  subjectName:'全部',
                },
                {
                  id:1,
                  subjectName:'课程实训习题',
                },
                {
                  id:2,
                  subjectName:'系统实训习题',
                },
              ],
              PreviewDialogtitle:'',
              PreviewDialog:false,
              SingleSelection:[],
              Multiple:[],
              Fillintheblanks:[],
              Judgment:[],
    });
    onMounted(()=>{
            methods.getstudentJobHistory();
            methods.getstudentJobHistory2();
            methods.get_select_subjectList();
        })
    const methods = {
      answer(answers) {
        let arr = [];
        answers.forEach((res) => {
          if (res.isAnswer == true) {
            arr.push(res.content)
          }
        })
        return arr.join(',')
      },
      yesanswer(questionAnswer) {
        let arr = [];
        questionAnswer.forEach((res) => {
          arr.push(res.content)
        })
        return arr.join(',')
      },
      //正确答案：
      answer2(answers) {
        let arr = [];
        answers.forEach((res) => {
          if (res.isAnswer == true) {
            if (res.isOrder == 1) {
              arr.push("A")
            } else if (res.isOrder == 2) {
              arr.push("B")
            } else if (res.isOrder == 3) {
              arr.push("C")
            } else if (res.isOrder == 4) {
              arr.push("D")
            }
          }
        })
        return arr.join(',')
      },
      //学生答案：
      yesanswer2(questionAnswer) {
        let arr = [];
        questionAnswer.forEach((res) => {
          if (res.isOrder == 1) {
            arr.push("A")
          } else if (res.isOrder == 2) {
            arr.push("B")
          } else if (res.isOrder == 3) {
            arr.push("C")
          } else if (res.isOrder == 4) {
            arr.push("D")
          }
        })
        return arr.join(',')
      },
      //点击获取历史作业试卷预览
      handleClick(row,PreviewDialogtitle){
        state.PreviewDialogtitle = PreviewDialogtitle
        let datas = {
          examinationPaperId: row.id
        }
        f_get_ShowJobHistory(datas).then((res) => {
          if (res.data.code == 1) {
            // console.log(res.data,'石星雨');
            let arr = [];
            state.SingleSelection = [];
            state.Multiple = [];
            state.Fillintheblanks = [];
            state.Judgment = [];
            res.data.data.forEach(item1 => {
              item1.forEach((item2) => {
                arr.push(item2)
              })
            });
            arr.forEach(item => {
              if (item.topicType == 1) {
                state.SingleSelection.push({
                  questionAnswer: item.questionAnswer,
                  isCorrect: item.isCorrect,
                  answers: item.answers,
                  isOrder: item.isOrder,
                  id: item.id,
                  testQuestionContent: item.testQuestionContent,
                  testQuestionAnalysis: item.testQuestionAnalysis,
                  topicType: item.topicType,
                  dev: ''
                })
              } else if (item.topicType == 2) {
                state.Multiple.push({
                  questionAnswer: item.questionAnswer,
                  isCorrect: item.isCorrect,
                  answers: item.answers,
                  isOrder: item.isOrder,
                  id: item.id,
                  testQuestionContent: item.testQuestionContent,
                  testQuestionAnalysis: item.testQuestionAnalysis,
                  topicType: item.topicType,
                  dev: []
                })
              } else if (item.topicType == 3) {
                state.Fillintheblanks.push({
                  questionAnswer: item.questionAnswer,
                  isCorrect: item.isCorrect,
                  answers: item.answers,
                  isOrder: item.isOrder,
                  id: item.id,
                  testQuestionContent: item.testQuestionContent,
                  testQuestionAnalysis: item.testQuestionAnalysis,
                  topicType: item.topicType,
                  questionAnswerContent: item.questionAnswerContent,
                  dev: ''
                })
              } else if (item.topicType == 4) {
                state.Judgment.push({
                  questionAnswer: item.questionAnswer,
                  isCorrect: item.isCorrect,
                  answers: item.answers,
                  isOrder: item.isOrder,
                  id: item.id,
                  testQuestionContent: item.testQuestionContent,
                  testQuestionAnalysis: item.testQuestionAnalysis,
                  topicType: item.topicType,
                  dev: ''
                })
              }
            });

            state.PreviewDialog = true;

          } else {
            ElMessage.error(res.data.message)
          }
        })
      },
      // 历史考试查询
      Querying(){
        methods.f_get_studentExaminationHistory()
      },
      //历史作业查询
      JobQuery(){
        methods.getstudentJobHistory()
      },
      //历史习题
      JobQuery3(){
        methods.getstudentJobHistory2()
      },
      //实训记录
      JobQuery1(){
        methods.f_get_studentTrainingHistory();
      },
        // 获取课程下拉数据
      get_select_subjectList(){
        let params = {
          majorId:'',
        }
        get_select_subjectList(params).then(res=>{
          if (res.data.code == 1) {
            let lists=[
              {
                subjectName:'全部',
                id:''
              }
            ]
            state.courseOptions = lists.concat(res.data.data);
          }
        })
      },
      // 获取学生的历史作业
      getstudentJobHistory(){
        let params = {
          studentId:getStorage('userId'),
          exercisesType:2,
          courseId:state.courseId,
          examinationName:state.name0,
          pageIndex:state.currentPage0,
          pageSize:8,
        } 
        f_get_studentJobHistory(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData0 = res.data.data.list;
            state.total0 = res.data.data.totalCount.Value;
          }
        })
      },
      handleCurrentChange0(val){
        state.currentPage0 = val;
        methods.getstudentJobHistory();
      },
      // 获取学生的实训成绩
      f_get_studentTrainingHistory(){
        let params = {
          studentId:getStorage('userId'),
          trainingExercisesType:state.courseId1,
          trainingName:state.name1,
          pageIndex:state.currentPage1,
          pageSize:8,
        }
        f_get_studentTrainingHistory(params).then(res=>{
         if (res.data.code == 1) {
            state.tableData1 = res.data.data.list;
            state.total1 = res.data.data.totalCount.Value;
         }

        })
      },
      handleCurrentChange1(val){
        state.currentPage1 = val;
        methods.f_get_studentTrainingHistory();
      },
      // 获取学生的历史考试
      f_get_studentExaminationHistory(){
        let params = {
          studentId:getStorage('userId'),
          subjectId:state.subjectId,
          examinationName:state.examinationName,
          pageIndex:state.currentPage2,
          pageSize:8
        }
        f_get_studentExaminationHistory(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData2 = res.data.data.list;
            state.total2 = res.data.data.totalCount.Value;
          }
        })
      },
      handleCurrentChange2(val){
        state.currentPage2 = val;
        methods.f_get_studentExaminationHistory();
      },
      // 获取学生的历史习题
      getstudentJobHistory2(){
        let params = {
          studentId:getStorage('userId'),
          exercisesType:1,
          courseId:state.courseId2,
          examinationName:state.name3,
          pageIndex:state.currentPage3,
          pageSize:8,
        }
        f_get_studentJobHistory(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData3 = res.data.data.list;
            state.total3 = res.data.data.totalCount.Value;
          }
        })
      },
      handleCurrentChange3(val){
        state.currentPage3 = val;
        methods.getstudentJobHistory2();
      },
          clikeyindex(index){
              state.keyindex = index;
              if (state.keyindex == 0) {
                state.currentPage0 = 1;
                methods.getstudentJobHistory();
              }else if (state.keyindex == 1) {
                state.currentPage1 = 1;
                methods.f_get_studentTrainingHistory();
              }else if(state.keyindex == 2){
                state.currentPage2 = 1;
                methods.f_get_studentExaminationHistory()
              }else{
                state.currentPage3 = 1;
                methods.getstudentJobHistory2();
              }
          },
          goroom(){
            router.push('/home/Centerintelexamine')
          },
          goexamination(){
            router.push('/home/Centerintelexamine')
          },

    };








    return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
    .keList{
      display: flex;
      align-items: center;

      div{
          margin: 4%;
          cursor:pointer;
      }
    }
    .keyindexclass{
        color: #76C8F6;
    }
</style>