<template>
    <div style="background:#fff">
        <div style="width:100%;height:60px;background:#fff;" class="keList">
            <div v-for="(item,index) in keList" :key="index" @click="clikeyindex(index)" :class="keyindex == index ? 'keyindexclass' : ''">{{item.name}}</div>
        </div> 
        <div style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
           <div style="margin-left: 30px;">
            <el-input v-model="name0" placeholder="课程名称"></el-input>
           </div>
            <div>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="JobQuery">查询</el-button>
            </div>
        </div>
        <div style="width:100%;height:500px;overflow-y:auto;margin-top:10px;display: flex;justify-content:flex-start;flex-wrap: wrap" >
            <el-card v-for="(item,index) in studentCourseList" :key="index" :body-style="{ padding: '0px' }"  style="margin-bottom:10px;width: 24.0%;height: 230px;margin-left:7px;" @click="quxuexi(item.id)">
                <div v-if="item.filePath">
                    <img style="width:100%;object-fit: cover;height: 160px;" :src="item.imgPath" alt="" srcset="">
                </div>
                <div v-else>
                    <el-empty :image-size="50" description="暂无封面" />
                </div>
                <div style="padding: 10px;">
                    <p style="font-size: 16px;font-weight: 600;">{{ item.courseName }}</p>
                    <p style="text-align: end;font-size: 14px;" v-show="courseType == 1">课程学习进度：{{ item.percent }}</p>
                </div>
               
          </el-card>
         
        </div>
        <div style="text-align:center">
            <el-pagination
            v-if="total>=8"
          :current-page="pageIndex"
          layout=" prev, pager, next,"
          :total="total"
          @current-change="handleCurrentChange"
        />
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {F_Get_subscribeCourseForStudentId} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { baseUrl } from '@/js/util.js'
import {courseData} from "@/js/caseApi";
import {f_get_studentCourse } from "@/js/homeListApi";
import {get_courseDataInfo} from "@/js/homeListApi.js"
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            keList:[
                {
                    name:"必修课"
                },
                {
                    name:"选修课"
                },
                
            ],
            studentCourseList:[],
            keyindex:0,
            courseType:1,
            pageIndex:1,
            pageSize:8,
            total:0,
            baseUrl:baseUrl,
            name0:'',
        })
        const methods = {
            //获取学生课程信息
            getstudentCourse() {
                let datas = {
                    studentId: getStorage('userId'),
                    courseType:state.courseType,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    courseName:state.name0,
                }
                f_get_studentCourse(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log(res.data);
                        state.studentCourseList = res.data.data.list;
                        state.total = res.data.data.totalCount.Value;
                        state.studentCourseList.forEach(element => {
                            if (element.filePath) {
                                element.imgPath = baseUrl+'/'+element.filePath
                            }
                            
                        });
                    }
                })
            },
            handleCurrentChange(val){
                state.pageIndex = val;
                methods.getstudentCourse();
                        },
            clikeyindex(index){
                //1、必修课程 2、选修课程
                state.name0 = '';
                if (index == 0) {
                    state.keyindex = index
                    state.courseType = 1
                    // console.log(state.courseType);
                    methods.getstudentCourse();
                }else if (index == 1){
                    state.keyindex = index
                    state.courseType = 2
                    // console.log(state.courseType);
                    methods.getstudentCourse();
                }
            },
            JobQuery(){
                methods.getstudentCourse();
            },
            quxuexi(courseId){
                setStorage('courseId',courseId)
                router.push('/home/courseDetailsCopy')
            },
        }

        onMounted(()=>{
            methods.getstudentCourse();
        })



    return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
    .keList{
        display: flex;
        align-items: center;

        div{
            margin: 4%;
            cursor:pointer;
        }
    }
    .keyindexclass{
        color: #76C8F6;
    }
    .TitleBackground1{
        width: 60%;
        background: url(../../../assets/img/courseTechingimg/shangkeblue.png) no-repeat;
        background-position: 100% 0%;
    }
    .TitleBackground2{
        width: 60%;
        background: url(../../../assets/img/courseTechingimg/shangwangreen.png) no-repeat;
        background-position: 100% 0%;
    }
    .TitleBackground3{
        width: 60%;
        background: url(../../../assets/img/courseTechingimg/jijiangorgen.png) no-repeat;
        background-position: 100% 0%;
    }
    .box-card{
        display: flex;
    }
</style>