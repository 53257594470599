<template>
  <div>
      <div style="width:100%;height:600px;background:#fff;">
          <p style="padding:30px;font-weight:600;">个人信息</p>
          <div style="width:50%;height:150px;margin-left:30px;float:left;line-height:30px">
              <p style="font-size:14px;">
                  <span style="color:#666666">真实姓名：</span>
                  <span>{{ userInfo.realName }}</span>
              </p>
              <p style="font-size:14px;">
                  <span style="color:#666666">性别：</span>
                  <span>{{ userInfo.sex == 1?'男':'女' }}</span>
              </p>
              <p style="font-size:14px;">
                <span style="color:#666666">学号：</span>
                <span>{{ userInfo.studentNum }}</span>
            </p>
              <p style="font-size:14px;">
                  <span style="color:#666666">QQ：</span>
                  <span>{{ userInfo.QQ }}</span>
              </p>
              <p style="font-size:14px;">
                  <span style="color:#666666">邮箱：</span>
                  <span>{{ userInfo.email }}</span>
              </p>
          </div>
          <div style="width:40%;height:150px;margin-left:30px;float:left;line-height:30px">
              <p style="font-size:14px;">
                  <span style="color:#666666">学校：</span>
                  <span>{{ userInfo.schoolName }}</span>
              </p>
              <p style="font-size:14px;">
                  <span style="color:#666666">院系：</span>
                  <span>{{ userInfo.departmentName }}</span>
              </p>
              <p style="font-size:14px;">
                  <span style="color:#666666">专业：</span>
                  <span>{{ userInfo.majorName }}</span>
              </p>
              <p style="font-size:14px;">
                <span style="color:#666666">班级：</span>
                <span>{{ userInfo.className }}</span>
            </p>
          </div>
          <div style="clear: both;"></div>
          <p style="padding:30px;font-weight:600;">账号信息</p>
              <p style="font-size:14px;padding-left:30px;">
                  <span style="color:#666666">手机号：</span>
                  <span>{{ userInfo.phoneNum }}</span>
              </p>
              <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-top:30px;margin-left:30px;" @click="cliavatar">修改密码</el-button>
     </div>


  <el-dialog
      v-model="centerDialogVisible"
      title="修改密码"
      width="30%"
      align-center
      :before-close="remoncliavatar"
    >
    <div class="centerDialog">

      <el-form
      label-position="right"
      label-width="100px"
      :model="forget"
      style="max-width: 460px"
    >
      <el-form-item label="旧密码 :">
        <el-input v-model="forget.oldPwd" placeholder="请输入旧密码" show-password />
      </el-form-item>
      <el-form-item label="新密码 :">
        <el-input v-model="forget.newPwd" placeholder="请输入新密码" show-password />
      </el-form-item>
      <el-form-item label="新密码 :">
        <el-input v-model="forget.configPwd" placeholder="请再次输入新密码" show-password />
      </el-form-item>
    </el-form>

    <el-button type="primary" round style="background-color: #76C8F6;border: none;position:absolute;bottom:10%;left:40%;" @click="confirmPwd">确认修改</el-button>

    </div>
  </el-dialog>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {F_Get_subscribeCourseForStudentId} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
import { f_get_userInfo, } from "@/js/homeListApi";
import {f_update_userPwd } from "@/js/homeListApi";
import { ElMessage } from "element-plus";
export default {
  setup() {
      const store = useStore();
  const router = useRouter();
  const state = reactive({
    centerDialogVisible:false,
    userInfo:{},
    formLabelAlign:{
      oldcipher:'',
      cipher:'',
      newcipher:'',
    },
    forget: {
                id: getStorage('userId'),
                oldPwd: '',
                newPwd: '',
                configPwd: ''
            }
    
  })
  onMounted(() => {
     methods.userList();
    });
  const methods = {
      //修改密码
      confirmPwd() {
                console.log(state.forget, 'forget')
                const { newPwd, oldPwd, configPwd } = state.forget
                if (newPwd == '' || oldPwd == '' || configPwd == '') {
                    ElMessage({
                        type: 'warning',
                        message: '密码不能为空',
                    })
                } else if (newPwd.length < 6 || configPwd < 6) {
                    ElMessage({
                        type: 'warning',
                        message: '密码长度不能小于6位',
                    })
                } else if (newPwd !== configPwd) {
                    ElMessage({
                        type: 'warning',
                        message: '两次密码输入不一样致',
                    })
                }else if (newPwd == oldPwd) {
                    ElMessage({
                        type: 'warning',
                        message: '新密码不能和旧密码相同',
                    })
                } else {
                  let params = {
                    id:getStorage('userId'),
                    oldPwd:state.forget.oldPwd,
                    newPwd:state.forget.newPwd,
                  }
                    f_update_userPwd(params).then(res => {
                        console.log(res, 'res')
                        const { code, data } = res.data
                        console.log(res.data)
                        if (code == 1) {
                            ElMessage({
                                type: 'success',
                                message: '修改成功',
                            })
                            state.dialogVisible = false
                            setTimeout(() => {
                                setStorage("ftoken", "");
                                setStorage("detailData", "");
                                setStorage("bclist", "");
                                setStorage("account", "");
                                setStorage("password", "");
                                setStorage("userId", "");
                                setStorage("StudentOrTeacher", "");
                                router.push("/loginPage");
                            }, 2000);

                        } else {
                            ElMessage({
                                type: 'error',
                                message: res.data.message,
                            })
                            state.dialogVisible = true
                        }
                    })
                }
            },
      //获取个人信息
      userList() {
        const params = {
          userId: getStorage('userId'),
          userType: getStorage("StudentOrTeacher")
        }
        f_get_userInfo(params).then(res => {
          console.log(res.data);
          const { code, data } = res.data
          if (code == 1) {
            let reg = /^(\d{3})\d*(\d{4})$/;
            data.phoneNum = data.phoneNum.replace(reg, '$1****$2')
            data.avater = data.userPic.filePath
            state.studentId = data.id
            state.userInfo = data;
          }
        })
      },
    cliavatar(){//点击换头像弹窗
      state.centerDialogVisible = true;
    },
    remoncliavatar(){
       state.forget={
              id: getStorage('userId'),
              oldPwd: '',
              newPwd: '',
              configPwd: ''
          }
      state.centerDialogVisible = false;
    },

  }
  return {
    ...toRefs(state),
    ...methods,
  };
  }
}
</script>
<style lang="scss" scoped>
  :deep div::-webkit-scrollbar {
      width: 5px; /*滚动条宽度*/
      height: 10px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  :deep div::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
      border-radius: 10px; /*滚动条的背景区域的圆角*/
      background-color: #fff; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  :deep div::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
      border-radius: 10px; /*滚动条的圆角*/
      background-color: #76C8F6; /*滚动条的背景颜色*/
  }
  .centerDialog{
    width: 30vw;
    height: 30vh;
  }

</style>
<style lang="scss">
.el-input__inner {

  border-radius: 21px;

}

</style>