<template>
  <div id="courseList">
    <div class="cm-container">
      <div class="teacher">
        <div style="width:65%;height:100px;margin:0 auto;padding:30px;position:relative;">
         
         
          <div style="color:#fff;position:absolute;top:20%;left:3.6%;cursor:pointer;" >
            <el-upload
            class="avatar-uploader"
            :action="baseUrl+'/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
            :show-file-list="false"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
          <el-avatar style="text-align: center;margin-top: 10px;float:left;border:3px solid #76C8F6;"
             :size=100  :src="imageUrl == null&&userInfo.sex==1 ? require('@/assets/img/icon/boy.png'): imageUrl == null&&userInfo.sex==2 ? require('@/assets/img/icon/girl.png'): imageUrl"></el-avatar>
          </el-upload>
          <p style="text-align: center;">更换头像</p>
          </div>
          <div style="margin-left:130px;color:#fff">
            <p style="font-weight:600;">{{ userInfo.realName }}</p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                院系：{{ userInfo.departmentName }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                性别：{{ userInfo.sex == 1 ? '男' : '女' }}
              </span>
            </p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                专业：{{ userInfo.majorName }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                学号：{{ userInfo.studentNum }}
              </span>
            </p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                班级：{{ userInfo.className }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                QQ：{{ userInfo.QQ }}
              </span>
            </p>
          </div>
        </div>

      </div>
      <div class="conentTeacher">
        <div style="width:12%;height:600px;background:#fff">
          <div style="width:100%;height:10%;text-align:center;line-height:50px;" v-for="(item, index) in dataList"
            :key="index">
            <p :class="titleIndex == index ? 'titleClass' : ''" style="cursor: pointer;" @click="titleClick(index)">{{
              item.title }}</p>
          </div>
        </div>
        <!-- 当前任务 -->
        <div style="width:86%;height:600px; display: flex;justify-content: space-between;" v-if="titleIndex == 0">
          <div style="width:73%;height:600px;background:#fff">
            <p style="font-weight:600;padding:10px;">待完成任务</p>
            <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="500px">
              <el-table-column type="index" label="序号" align="center" />
              <el-table-column prop="courseName" label="课程名称" align="center" >
                <template #default="{ row }">
                  <span v-if="row.courseName">
                    {{row.courseName}}
                  </span>
                  <span v-if="!row.courseName">
                    - -
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="taskName" label="任务名称" width="180" align="center" />
              <el-table-column prop="exercise" label="任务类型" align="center" >
                <template #default="{ row }">
                  <span v-if="row.taskType == 1">
                    课程
                  </span>
                  <span v-if="row.taskType == 2">
                    课后作业
                  </span>
                  <span v-if="row.taskType == 3">
                    考试
                  </span>
                  <span v-if="!row.taskType">
                    - -
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="时间" width="180" align="center" >
                <template #default="{ row }">
                  <span>
                    {{ `${row.startTime} -- ${row.endTime}`}}
                  </span>
                  <span v-if="!row.startTime || !row.endTime">
                    - -
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <!-- <template #default="{ row }">
                  <span style="cursor: pointer;color:#76C8F6" @click="handLink(row.exercise)">
                    {{ statusList.find((item) => item.exercise === row.exercise).name }}
                  </span>
                </template> -->
                <template #default="{ row }">
                  <span style="cursor: pointer;color:#76C8F6" v-if="row.status == 0">
                    - -
                  </span>
                  <span style="cursor: pointer;color:#76C8F6" v-if="row.status == 1" @click="handLink(row.taskType,row.status,row)">
                    去预习
                  </span>
                  <span style="cursor: pointer;color:#76C8F6" v-if="row.status == 2" @click="handLink(row.taskType,row.status,row)">
                    去上课
                  </span>
                  <span style="cursor: pointer;color:#76C8F6" v-if="row.status == 3" @click="handLink(row.taskType,row.status,row)">
                    去完成
                  </span>
                  <span style="cursor: pointer;color:#76C8F6" v-if="row.status == 4" @click="handLink(row.taskType,row.status,row)">
                    去考试
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
              <el-pagination small v-model:current-page="pageIndex1" v-model:page-size="pageSize1"
                layout="prev, pager, next" :total="total1" @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1" />
            </div>
          </div>
          <div style="width:25%;height:600px;background:#fff">
            <p style="font-weight:600;padding:10px;">
              <span>消息</span>
              <span style="float:right;font-size:12px;color:#0079FF;font-weight:0;cursor: pointer;" @click="markall()">全部已读</span>
            </p>
            <div style="height:500px;max-height:500px;overflow-y:auto;border-bottom:1px solid #dcdfe6" v-if="userMessageList.length != 0">
              <el-badge class="item" style="margin:10px" v-for="(item) in userMessageList" :key="item.id" :is-dot="item.notificationStatus == 1 ? true : false"
              >
                <el-card class="box-card">
                  <!-- <span style="display:block;float:right;color:red;width:"></span> -->
                  <p style="width:95%;font-size:14px">
                    {{item.content}}
                  </p>
                  <p v-if="item.notificationStatus == 2" style="text-align:end;font-weight: 400;color: #76C8F6;font-size:12px;cursor: pointer;">已读</p>
                  <p v-if="item.notificationStatus == 1" style="text-align:end;font-weight: 400;color: #76C8F6;font-size:12px;cursor: pointer;" @click="markread(item)">标记已读</p>
                </el-card>
              </el-badge>
              <div style="width:95%;font-size:14px;text-align:center;margin-top:50px;" v-if="userMessageList.length == 0">
                暂无消息</div>
            </div>
            <el-pagination small v-model:current-page="pageIndex2" v-model:page-size="pageSize2"
              layout="prev, pager, next" :total="total2" @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2" />
          </div>
        </div>

        <div style="width:86%;height:600px;" v-if="titleIndex == 1">
          <course-teacher v-if="titleIndex == 1"></course-teacher>
        </div>
        <div style="width:86%;height:600px;" v-if="titleIndex == 2">
          <question-template v-if="titleIndex == 2"></question-template>
        </div>
        <div style="width:86%;height:600px;" v-if="titleIndex == 3">
          <topic-template v-if="titleIndex == 3"></topic-template>
        </div>

      </div>
    </div>

    <!-- 换头像弹窗 -->
    <el-dialog v-model="centerDialogVisible" title="更换头像" width="30%" align-center>
      <div class="centerDialog">
        <div class="centerDialogleft">
          <el-upload v-model:file-list="fileList" class="upload-demo"
            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple :limit="1">
            <el-button type="primary" round
              style="background-color: #76C8F6;border: none;position:absolute;top:35%;left:17%;">选择图片</el-button>
            <template #tip>
              <div class="el-upload__tip" style="position:absolute;top:45%;left:15%;">
                5M以内，格式jpg、png
              </div>
            </template>
          </el-upload>
        </div>
        <div class="centerDialogright" style="position:relative;">
          <div style="margin:3%;">
            头像预览
          </div>
          <el-avatar
            style="text-align: center;margin-top: 10px;float:left;border:3px solid #76C8F6;position:absolute;top:30%;left:30%;"
            :src="require('@/assets/img/courseTechingimg/xuesheng.jpg')" :size=100></el-avatar>
        </div>
      </div>
      <el-button type="primary" round
        style="background-color: #ffffff;color:#76C8F6;border: 1px solid #76C8F6;position:absolute;bottom:10%;left:25%;padding:0 8%;"
        @click="remoncliavatar">取消</el-button>
      <el-button type="primary" round
        style="background-color: #76C8F6;border: none;position:absolute;bottom:10%;right:25%;padding:0 8%;"
        @click="noremoncliavatar">确定</el-button>
    </el-dialog>

  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import { F_Get_subscribeCourseForStudentId } from '@/js/teachingSquare'
import { setStorage, getStorage, Newdata } from "@/js/common";
import courseTeacher from "./courseManagementTeacher.vue";
import questionTemplate from "./questionBankTemplate.vue";
import topicTemplate from "./topicTemplate.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { courseData } from "@/js/caseApi";
import { f_get_userInfo, f_get_studentTaskPageList, f_get_userMessage ,f_update_userMsgStatus,f_go_courseExercises,f_go_examination} from "@/js/homeListApi";
import { DeleteFiles,f_update_userPic} from "@/js/courseManagementApi"
import { ElMessage } from 'element-plus';
import { baseUrl } from '@/js/util.js'
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      baseUrl:baseUrl,
      currentPage4: 1,
      imageUrl:'',
      userInfo: [],
      headers: {
                token: getStorage('ftoken'),
                operate: true
            },
      //任务表格数据
      tableData: [
        // {
        //   name:'建筑力学',
        //   time:'2019-02-12 12:30:60',
        //   classroom:'第一章 第二节',
        //   exercise:'课程',

        // },{
        //   name:'测量工程',
        //   time:'2019-02-12 12:30:60',
        //   classroom:'第一章 第一节',
        //   exercise:'课程',

        // },{
        //   name:'施工组织与设计',
        //   time:'2019-02-12 12:30:60',
        //   classroom:'第一章第一节习题',
        //   exercise:'课后作业',

        // },{
        //   name:'智能测绘',
        //   time:'2019-02-12 12:30:60',
        //   classroom:'期末考试',
        //   exercise:'考试',

        // }
      ],
      //消息数据
      userMessageList: [],
      titleIndex: getStorage("titleIndex"),
      dataList: [
        {
          title: '当前任务'
        }, {
          title: '课程信息'
        }, {
          title: '考试记录'
        }, {
          title: '个人资料'
        }, {
          title: '帮助中心'
        },
      ],
      statusList: [
        {
          exercise: '课程',
          name: '去上课',
        },
        {
          exercise: '课后作业',
          name: '去完成',
        },
        {
          exercise: '考试',
          name: '去考试',
        },
      ],
      imgUrlId:[],
      centerDialogVisible: false,
      pageIndex1: 1,
      pageSize1: 10,
      total1: 0,
      pageIndex2: 1,
      pageSize2: 5,
      total2: 0,
      studentId: '',

    });

    const methods = {
      // 修改上传头像成功
      f_update_userPic(){
        let params = {
          id:getStorage('userId'),
          fileId:state.imgUrlId.toString()
        }
        f_update_userPic(params).then(res=>{
          if (res.data.code== 1) {
            ElMessage.success('修改成功')
          }else{
            ElMessage.error(res.data.message)
          }
        })
      },
      handleAvatarSuccess(res){
        console.log(res);
        if (res.code == 1) {
          state.imageUrl = state.baseUrl+'/'+res.data[0].filePath;
          state.imgUrlId = [];
          state.imgUrlId.push(res.data[0].Id);
          methods.f_update_userPic();
        }else{
          ElMessage.error(res.message)
        }
       
        
      },
      
      beforeAvatarUpload(res){
        if (res.code == 1) {
          if (state.imgUrlId.length>0) {
          DeleteFiles(state.imgUrlId).then(res => {
                    if (res.data.code == 1) {
                        state.imgUrlId = [];
                        state.imageUrl = null;
                    }
                })
        }
        }
        
        
      },
      //获取个人信息
      userList() {
        const params = {
          userId: getStorage('userId'),
          userType: getStorage("StudentOrTeacher")
        }
        f_get_userInfo(params).then(res => {
          console.log(res.data);
          const { code, data } = res.data
          if (code == 1) {
            let reg = /^(\d{3})\d*(\d{4})$/;
            data.phoneNum = data.phoneNum.replace(reg, '$1****$2')
            data.avater = data.userPic.filePath;
            if (data.userPic.filePath) {
              state.imageUrl = state.baseUrl+'/'+data.userPic.filePath;
            }else{
              state.imageUrl = null;
            }
            console.log(state.imageUrl);
            state.imgUrlId = [];
            state.imgUrlId.push(data.userPic.Id);
            state.studentId = data.id
            state.userInfo = data
            // localStorage.setItem('userInfo',JSON.stringify(data))
            methods.getTaskPageList();
          }
        })
      },
      //获取学生任务
      getTaskPageList() {
        let datas = {
          studentId: state.studentId,
          pageIndex: state.pageIndex1,
          pageSize: state.pageSize1,
        }
        console.log(datas, '获取学生任务传参');
        f_get_studentTaskPageList(datas).then((res) => {
          if (res.data.code == 1) {
            state.tableData = res.data.data.list
            state.total1 = res.data.data.totalCount.Value
            console.log(state.tableData, '获取学生任务');
          }
        })
      },
      //获取学生任务更改页码
      handleSizeChange1() {
        methods.getTaskPageList();
      },
      handleCurrentChange1() {
        methods.getTaskPageList();
      },
      //获取用户未读消息
      getuserMessage() {
        let datas = {
          userId: getStorage('userId'),
          pageIndex: state.pageIndex2,
          pageSize: state.pageSize2,
        }
        f_get_userMessage(datas).then((res) => {
          if (res.data.code == 1) {
            state.userMessageList = res.data.data.list
            state.total2 = res.data.data.totalCount.Value
            console.log(state.userMessageList, '获取用户未读消息');
          }
        })
      },
      //获取学生消息更改页码
      handleSizeChange2() {
        methods.getuserMessage();
      },
      handleCurrentChange2() {
        methods.getuserMessage();
      },
      //标记已读
      markread(item) {
        let params = {
          userId: getStorage('userId'),
          notificationId: item.id
        }
        f_update_userMsgStatus(params).then(res => {
          if (res.data.code == 1) {
            ElMessage.success('成功标记已读')
          }else{
            ElMessage.error(res.data.message)
          }
          methods.getuserMessage()
        })
      },
      //全部已读
      markall() {
        let params = {
          userId: getStorage('userId'),
          notificationId: ''
        }
        f_update_userMsgStatus(params).then(res => {
          console.log(res.data)
          if (res.data.code == 1) {
            ElMessage.success('全部标记已读成功')
          }else{
            ElMessage.error(res.data.message)
          }
          methods.getuserMessage()
        })
      },
      titleClick(index) {
        state.titleIndex = index;
        setStorage("titleIndex", state.titleIndex)
        if (index == 4) {
          router.push('/home/studenthelpCenter')
        }
      },


      cliavatar() {//点击换头像弹窗
        state.centerDialogVisible = true;
      },
      remoncliavatar() {//点击换头像弹窗
        state.centerDialogVisible = false;
      },
      noremoncliavatar() {//点击换头像弹窗
        state.centerDialogVisible = false;
      },


      handLink(taskType,status,row) {
        if (taskType == 1 && status == 1) {//点击去预习
          store.commit("SetnarbarMenuActive", 2);
          setStorage("narbarMenuActive", 2);
          setStorage('courseId',row.courseId)
          router.push({path:'/home/courseDetailsCopy'})
        } else if (taskType == 1 && status == 2){//去上课
          store.commit("SetnarbarMenuActive", 2);
          setStorage("narbarMenuActive", 2);
          setStorage('courseId',row.courseId)
          router.push({path:'/home/courseDetailsCopy'})
        } else if (taskType == 2 && status == 3){//去完成
          let datas = {
            studentTaskId:row.id,//任务id
          }
          f_go_courseExercises(datas).then((res) => {
            if (res.data.code == 1) {
              setStorage('homework',res.data.data)
              console.log(res.data.data, '去做课后作业');
              router.push({path:'/home/Dohomework'})
            }else{
              ElMessage.error(res.data.message)
            }
          })
          
        } else if (taskType == 3 && status == 4){//去考试
          let datas = {
            studentTaskId:row.id,//任务id
          }
          f_go_examination(datas).then((res) => {
            if (res.data.code == 1) {
              setStorage('Examination',res.data.data)
              console.log(res.data.data, '去考试');
              router.push({path:'/home/studentExamination'})
            }else{
              ElMessage.error(res.data.message)
            }
          })
        }

        // const routeJump = new Map([
        //   [
        //     '课程',
        //     () => router.push('/home/courseDetails')
        //   ],
        //   [
        //     '习题',
        //     () => router.push('')
        //   ], [
        //     '考试',
        //     () => router.push('')
        //   ]
        // ])
        // routeJump.get(str)?.()
      }
    };
    onMounted(() => {
     methods.userList();
      methods.getuserMessage();
    });
    onUnmounted(() => { });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: { courseTeacher, questionTemplate, topicTemplate },
};
</script>
<style lang="scss" scoped>
#courseList {
  height: 100%;
  position: relative;

  .cm-container {
    width: 66.5%;
    height: 100%;
    margin: 0 auto;

    .teacher {
      width: 100%;
      height: 200px;
      background-image: url('../../../assets/img/head/teacherBag.png');
      margin-top: 10px;
      ;
    }

    .conentTeacher {
      width: 100%;
      height: 600px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    .titleClass {
      color: #76C8F6;
    }

    .el-table {
      :deep .el-table__body-wrapper::-webkit-scrollbar {
        width: 5px;
        /*滚动条宽度*/
        height: 10px;
        /*滚动条高度*/
      }

      /*定义滚动条轨道 内阴影+圆角*/
      :deep .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset;
        /*滚动条的背景区域的内阴影*/
        border-radius: 10px;
        /*滚动条的背景区域的圆角*/
        background-color: #fff;
        /*滚动条的背景颜色*/
      }

      /*定义滑块 内阴影+圆角*/
      :deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset;
        /*滚动条的内阴影*/
        border-radius: 10px;
        /*滚动条的圆角*/
        background-color: #76C8F6;
        /*滚动条的背景颜色*/
      }
    }

    :deep div::-webkit-scrollbar {
      width: 5px;
      /*滚动条宽度*/
      height: 10px;
      /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px #fff inset;
      /*滚动条的背景区域的内阴影*/
      border-radius: 10px;
      /*滚动条的背景区域的圆角*/
      background-color: #fff;
      /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px #76C8F6 inset;
      /*滚动条的内阴影*/
      border-radius: 10px;
      /*滚动条的圆角*/
      background-color: #76C8F6;
      /*滚动条的背景颜色*/
    }
  }

}

@media screen and (min-width: 1600px) {
  #courseList {
    .cm-container {
      width: 1400px;


    }
  }

  .centerDialog {
    width: 30vw;
    height: 400px;
    display: flex;
    justify-content: space-evenly
  }

  .centerDialogleft {
    width: 45%;
    height: 70%;
    border: 1px solid #F7F8FA;
  }

  .centerDialogright {
    width: 40%;
    height: 70%;
    border: 1px solid #F7F8FA;
  }

}</style>
<style lang="scss">.el-avatar>img {
  display: block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.el-dialog__body {
  padding: 0;
}</style>
  